import { enumMovementTypes } from "@/models/IPropertyAccounting";

export const month1 = {
  month: 1,
  invoice: null,
  totalAmount: { amount: 1500, currency: "EUR" },
  movements: [
    {
      type: enumMovementTypes.MONTHLY_INCOME,
      concept: "income",
      amount: { amount: 1800, currency: "EUR" },
      date: "2023-01-15T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "Concept 1",
      amount: { amount: 300, currency: "EUR" },
      date: "2023-01-12T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "Concept 2",
      amount: { amount: 60, currency: "EUR" },
      date: "2023-01-05T00:00:00.000000Z",
    },
  ],
};

export const month2 = {
  month: 2,
  invoice: {
    uuid: "7c757631-6b1d-4726-adc1-0103870e1033",
    downloadUrl:
      "https://housfy-api-storage-dev.s3.eu-west-1.amazonaws.com/private/7c757631-6b1d-4726-adc1-0103870e1033?response-content-disposition=attachment%3B%20filename%3D%22L2201121.pdf%22&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJZBZ53EFUWBT2USA%2F20230608%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230608T105809Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=41ca707f5002a8c964f56c6f94167a64d3f974670d323eea6f4f1b3b5547ce53",
    viewUrl:
      "https://housfy-api-storage-dev.s3.eu-west-1.amazonaws.com/private/7c757631-6b1d-4726-adc1-0103870e1033?response-content-disposition=inline%3B%20filename%3D%22L2201121.pdf%22&response-content-type=application%2Fpdf&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJZBZ53EFUWBT2USA%2F20230608%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230608T105809Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=de91cc5cbc2eaa6035601707829a38478c680b38afdbe5856d8f20102a438ccb",
    url: "https://housfy-api-storage-dev.s3.eu-west-1.amazonaws.com/private/7c757631-6b1d-4726-adc1-0103870e1033",
    expiresAt: "2023-06-08T12:58:09Z",
  },
  totalAmount: { amount: 1240, currency: "EUR" },
  movements: [
    {
      type: enumMovementTypes.MONTHLY_INCOME,
      concept: "income",
      amount: { amount: 1500, currency: "EUR" },
      date: "2023-02-01T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "gastos",
      amount: { amount: 260, currency: "EUR" },
      date: "2023-02-03T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "gasticos",
      amount: { amount: 53, currency: "EUR" },
      date: "2023-02-03T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "moroso",
      amount: { amount: 20, currency: "EUR" },
      date: "2023-02-20T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "el jardinero",
      amount: { amount: 12, currency: "EUR" },
      date: "2023-02-15T00:00:00.000000Z",
    },
  ],
};

export const month3 = {
  month: 3,
  invoice: null,
  totalAmount: { amount: 1500, currency: "EUR" },
  movements: [
    {
      type: enumMovementTypes.MONTHLY_INCOME,
      concept: "income",
      amount: { amount: 1500, currency: "EUR" },
      date: "2023-02-01T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "gastos",
      amount: { amount: 260, currency: "EUR" },
      date: "2023-02-03T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "gasticos",
      amount: { amount: 53, currency: "EUR" },
      date: "2023-02-03T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "moroso",
      amount: { amount: 20, currency: "EUR" },
      date: "2023-02-20T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "el jardinero",
      amount: { amount: 12, currency: "EUR" },
      date: "2023-02-15T00:00:00.000000Z",
    },
  ],
};

export const month4 = {
  month: 4,
  invoice: null,
  totalAmount: { amount: 1500, currency: "EUR" },
  movements: [
    {
      type: enumMovementTypes.MONTHLY_INCOME,
      concept: "income",
      amount: { amount: 1500, currency: "EUR" },
      date: "2023-02-01T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "gastos",
      amount: { amount: 260, currency: "EUR" },
      date: "2023-02-03T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "gasticos",
      amount: { amount: 53, currency: "EUR" },
      date: "2023-02-03T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "moroso",
      amount: { amount: 20, currency: "EUR" },
      date: "2023-02-20T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "el jardinero",
      amount: { amount: 12, currency: "EUR" },
      date: "2023-02-15T00:00:00.000000Z",
    },
  ],
};
export const month5 = {
  month: 5,
  invoice: null,
  totalAmount: { amount: 1500, currency: "EUR" },
  movements: [
    {
      type: enumMovementTypes.MONTHLY_INCOME,
      concept: "income",
      amount: { amount: 1500, currency: "EUR" },
      date: "2023-02-01T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "gastos",
      amount: { amount: 260, currency: "EUR" },
      date: "2023-02-03T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "gasticos",
      amount: { amount: 53, currency: "EUR" },
      date: "2023-02-03T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "moroso",
      amount: { amount: 20, currency: "EUR" },
      date: "2023-02-20T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "el jardinero",
      amount: { amount: 12, currency: "EUR" },
      date: "2023-02-15T00:00:00.000000Z",
    },
  ],
};
export const month6 = {
  month: 6,
  invoice: null,
  totalAmount: { amount: 5000, currency: "EUR" },
  movements: [
    {
      type: enumMovementTypes.MONTHLY_INCOME,
      concept: "income",
      amount: { amount: 1500, currency: "EUR" },
      date: "2023-06-08T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "gastos",
      amount: { amount: 260, currency: "EUR" },
      date: "2023-06-07T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "gasticos",
      amount: { amount: 53, currency: "EUR" },
      date: "2023-06-03T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "moroso",
      amount: { amount: 20, currency: "EUR" },
      date: "2023-06-20T00:00:00.000000Z",
    },
    {
      type: enumMovementTypes.REPERCUSSION,
      concept: "el jardinero",
      amount: { amount: 12, currency: "EUR" },
      date: "2023-06-15T00:00:00.000000Z",
    },
  ],
};

export const FAKE_CHART_DATA = {
  labels: [
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SEPT",
    "OCT",
    "NOV",
    "DIC",
  ],
  datasets: [
    {
      backgroundColor: ["#11AEFA"],
      data: [
        month1.totalAmount.amount,
        month2.totalAmount.amount,
        month3.totalAmount.amount,
        month4.totalAmount.amount,
        month5.totalAmount.amount,
        month6.totalAmount.amount,
      ],
    },
  ],
};
